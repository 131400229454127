/* DESKTOP  */
@media only screen and (min-width: 768px) {
  .article-header,
  .article-body {
    /* margin-top: 10px; */
    /* margin-bottom: 0px; */
    padding-left: 0px;
    padding-right: 0px;
    /* background: black;
    color: white; */
  }
}
