html {
  -ms-overflow-style: none;
  /* overflow: scroll -moz-scrollbars-none; */
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

textarea,
input {
  font-size: 0.9em;
  font-family: sans-serif;
  outline: none;
  resize: none;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 7.5px;
  padding: 0px;
  margin: -1px -1px 1px -1px;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

li {
  padding: 0px;
  margin: 0px;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  text-align: left;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.overflowHidden {
  overflow: hidden;
  /* border: red 2px dashed; */
}

.padding {
  padding: 10px;
  /* border: red 2px dashed; */
}

.padding-half {
  padding: 5px;
  /* border: red 2px dashed; */
}

.filter {
  text-align: left;
  /* flex: 1; */
}

.clickable {
  cursor: pointer;
}

.button {
  display: flex;
  color: white;
  background-color: #f299b8;
  font-weight: bold;
  border-color: transparent;
  border-width: 0px;
  border-radius: 7.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
}

.react-autosuggest__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* 
.boxsizingBorder {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
} */

/* Desktop */

@media only screen and (min-width: 800px) {
  /* body {
        width: 100%;
        margin: auto;
    } */
  .content-padded {
    width: 800px;
    /* text-align: center; */
    margin: auto;
  }
  .content-padded-large {
    padding-left: 50px;
    padding-right: 50px;
  }
}
