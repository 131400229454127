.image-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #222;
  max-width: 800px;
  max-height: 450px;
  /* max-height: 55vh; */
  /* min-height: 300px; */
}

.image-cover-full-screen {
  width: 100%;
  height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
  /* height: 56.25vw; height:width ratio = 9/16 = .5625  */
}

.image-cover-padded {
  width: 100%;
  height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
  /* height: 56.25vw; height:width ratio = 9/16 = .5625  */
}

.img-gallery-img {
  width: 100%;
  height: 75vh;
  max-height: 75vh;
  background-color: black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* DESKTOP  */
@media only screen and (min-width: 768px) {
  .image-cover-padded {
    width: 800px;
    height: 450px;
    /* height: 432px;  */
    /* 16 / 9 */
  }
}
