.react-select-list-item {
  /* background-color: rgba(153, 153, 153, .08);
  border: 1px solid rgba(153, 153, 153, 0.24);
  color: #999; */
}

.react-selected-list-item {
  /* background-color: rgba(153, 153, 153, .08);
  border: 1px solid rgba(153, 153, 153, 0.24);
  color: #999; */
}

.Select-control {
  border-radius: 7.5px !important;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 2px;
}

.Select-menu-outer {
  border-radius: 0px 0px 7.5px 7.5px !important;
  /* border-color: rgba(141, 221, 241, 1.0) !important; */
  border-color: rgba(0, 0, 0, 0.1) !important;
  /* border-top-color: rgba(0, 0, 0, 0.0); */
  margin-top: 0px;
  padding: 0px;
  overflow-x: hidden;
  /* padding-bottom: 5px; */
}

.Select--multi .Select-value {
  background-color: rgba(65, 167, 192, 0.08) !important;
  border: 1px solid rgba(65, 167, 192, 0.24) !important;
  color: rgba(65, 167, 192, 1) !important;
  border-radius: 7.5px !important;
  padding: 2px 6px !important;
  margin: 2px !important;
}

.Select--multi .Select-value-icon {
  border-right: 1px solid rgba(65, 167, 192, 0.24) !important;
  padding: 0px 4px 0px 2px !important;
}

.Select--multi .Select-value-label {
  color: rgba(65, 167, 192, 1) !important;
}

.Select-value-label {
  /* color: rgba(141, 221, 241, 1.0)  !important; */
  color: rgba(0, 0, 0, 1) !important;
  vertical-align: center !important;
  justify-content: center;
  align-items: center;
}

.Select-value {
  display: flex;
  align-items: center;
}

.Select-placeholder {
  display: flex;
  align-items: center;
}

.Select.is-focused.is-open > .Select-control {
  border-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 7.5px 7.5px 0px 0px !important;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

.Select.is-focused:not(is-open) > .Select-control {
  border-color: rgba(141, 221, 241, 1) !important;
  border-radius: 7.5px !important;
}
