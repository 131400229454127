.react-tabs__tab-list {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border: none;
  padding: 0px;
  width: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  /* justify-content: space-between; */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
}

.react-tabs__tab-list::-webkit-scrollbar {
  display: none;
  background: transparent;
}

.react-tabs {
  padding: 0px;
  margin: 0px;
  border: none;
}

.react-tabs__tab {
  background: white;
  border-top: none;
  border-left: none;
  border-right: none;
  opacity: 0.22;
  border-bottom: solid 4px transparent;
  /* white-space: nowrap; */
}

.react-tabs__tab--selected {
  background: white;
  opacity: 1;
  /* border-bottom: solid 4px rgb(230, 158, 183); */
  /* white-space: nowrap; */
}

.react-tabs__tab:focus {
  /* white-space: nowrap; */
  outline: none;
  outline-style: none;
  box-shadow: none;
  background: white;
  border-top: none;
  border-left: none;
  border-right: none;
  opacity: 1;
  /* border-bottom: solid 4px rgb(230, 158, 183); */
}

.react-tabs__tab:focus:after {
  /* hack for very weird bug */
  top: 9000px;
  height: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  outline: none;
  outline-style: none;
  box-shadow: none;
  background: white;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border-bottom: solid 4px rgb(230, 158, 183); */
}

/* Desktop */
@media only screen and (min-width: 768px) {
  .filter {
    /* text-align: center; */
  }

  .react-tabs__tab-list {
    /* display: inline-block; */
  }

  .filter-categories .react-tabs__tab-list {
    /* display: inline; */
  }
}

/* small */
@media only screen and (max-width: 360px) {
  .react-tabs__tab-list {
    margin: 5px 0px 0px 0px;
  }

  .react-tabs__tab {
    background: white;
    padding: 7px 6px 7px 6px;
  }
}
