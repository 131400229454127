.text-input {
  max-height: unset !important;
}

.primitive-text {
  font-size: 1em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  letter-spacing: 0.02em;
  /* -webkit-box-shadow:inset 0px 0px 0px 10px #f00;
  -moz-box-shadow:inset 0px 0px 0px 10px #f00;
  box-shadow:inset 0px 0px 0px 10px #f00; */
}

.primitive-text-xsmall {
  font-size: 0.7em;
}

.primitive-text-small {
  font-size: 1em;
}

.primitive-text-medium {
  font-size: 1.15em;
}

.primitive-text-mediumBig {
  font-size: 1.3em;
}

.primitive-text-big {
  font-size: 1.6em;
}

.primitive-text-huge {
  font-size: 2.7em;
}

/* Desktop */
@media only screen and (min-width: 768px) {
  .primitive-text-xsmall {
    font-size: 0.75em;
  }
  .primitive-text-small {
    font-size: 1.1em;
  }

  .primitive-text-medium {
    font-size: 1.35em;
  }

  .primitive-text-mediumBig {
    font-size: 1.4em;
  }

  .primitive-text-big {
    font-size: 1.8em;
  }

  .primitive-text-huge {
    font-size: 2.7em;
  }
}
